import React from "react"
import Footer from "../../components/footer"
import "../../styles/byTheNumber.less"
import SEO from "../../components/SEO"
import { SolutionTopBanner } from "../../components/block"
const loadText = require("src/utils").loadText
const { china, title } = loadText("by-the-numbers")
// const CopyRightTele = () => (
//   <div style={{ height: "16px", width: "100%" }}></div>
// )
// const CopyRightWe = () => <div style={{ height: "16px", width: "100%" }}></div>
export const MarketOverview = ({ src }) => (
  <div className="byTheNumber-market flex-container">
    <div className="byTheNumber-market-bg">
      <div className="font-36 semi-bold t-color">{title.market}</div>
      {/* <CopyRightTele /> */}
      <div className="imgContainer mt-56">
        <picture>
          <source
            srcSet={`${src.replace(".png", "")}-m.png`}
            media="(max-width: 640px)"
          />
          <img src={src} alt={title.market} />
        </picture>
      </div>
    </div>
  </div>
)
export const Sub = ({ src }) => (
  <div className="byTheNumber-sub">
    <div className="font-36 semi-bold t-color">{title.subscriber}</div>
    <div className="imgContainer mt-56">
      <picture>
        <source
          srcSet={`${src.replace(".jpg", "")}-m.png`}
          media="(max-width: 640px)"
        />
        <img src={src} alt={title.subscriber} />
      </picture>
    </div>
  </div>
)
export const ConnectionType = ({ leftSrc, rightSrc, bgColor, bgPic }) => (
  <div
    className="byTheNumber-connectionType"
    style={{
      background: bgColor,
      backgroundSize: "auto 820px",
      backgroundRepeat: "no-repeat",
    }}
  >
    <div
      className="full-bg"
      style={{
        backgroundImage: `url("${bgPic}")`,
        backgroundSize: "auto 820px",
      }}
    >
      <div className="font-36 semi-bold t-color">{title.connectionType}</div>
      {/* <CopyRightTele /> */}

      <div className="mt-56">
        <div className="imgContainer">
          <picture>
            <source
              srcSet={`${leftSrc.replace(".png", "")}-m.png`}
              media="(max-width: 640px)"
            />
            <img src={leftSrc} alt={title.market} />
          </picture>
          {/* <img src={leftSrc} alt="" /> */}
        </div>
        <div className="imgContainer">
          <picture>
            <source
              srcSet={`${rightSrc.replace(".png", "")}-m.png`}
              media="(max-width: 640px)"
            />
            <img src={rightSrc} alt={title.market} />
          </picture>
          {/* <img src={rightSrc} alt="" /> */}
        </div>
      </div>
    </div>
  </div>
)
export const Average = ({ src1, src2, bgColor }) => (
  <div className="byTheNumber-average">
    <div className="font-36 semi-bold t-color">{title.daily}</div>
    {/* <CopyRightWe /> */}
    <div
      className="imgContainer"
      style={{ maxWidth: "992px", margin: "56px auto 0 auto" }}
    >
      <picture>
        <source
          srcSet={`${src1.replace(".jpg", "")}-m.png`}
          media="(max-width: 640px)"
        />
        <img src={src1} alt={title.daily} />
      </picture>
    </div>

    <div
      style={{ background: bgColor, paddingBottom: "332px" }}
      className="bg-Color"
    >
      <div className="font-36 semi-bold t-color" style={{ marginTop: "92px" }}>
        {title.share}
      </div>
      {/* <CopyRightWe /> */}
      <div
        className="imgContainer"
        style={{ maxWidth: "843px", margin: "56px auto 0 auto" }}
      >
        <picture>
          <source
            srcSet={`${src2.replace(".png", "")}-m.png`}
            media="(max-width: 640px)"
          />
          <img src={src2} alt={title.daily} />
        </picture>
        {/* <img src={src2} alt={title.share} /> */}
      </div>
    </div>
  </div>
)
export const Percentage = ({
  src1,
  srcGroup,
  srcGroupM,
  src2Left,
  src2Right,
  src2Group,
}) => (
  <div className="byTheNumber-percentage">
    <div className="top-percentage">
      <div className="top block-middle">
        <div className="font-36 semi-bold t-color">{title.whoPercentage}</div>
        {/* <CopyRightTele /> */}

        <div
          style={{ margin: "56px auto 0 auto", maxWidth: "905px" }}
          className="imgContainer mt-56 middle-block"
        >
          <picture>
            <source
              srcSet={`${src1.replace(".jpg", "")}-m.png`}
              media="(max-width: 640px)"
            />
            <img src={src1} alt={title.gamePercentage} />
          </picture>
          {/* <img src={src1} alt={title.gamePercentage} /> */}
        </div>
        <div
          className="font-36 semi-bold t-color"
          style={{ marginTop: "104px" }}
        >
          {title.gamePercentage}
        </div>
        {/* <CopyRightTele /> */}
        {/* <img src={src2} alt={title.appPercentage} />
         */}
        <div className="mt-56">
          <div className="srcGroup">
            {srcGroup.map(({ key, src }) => (
              <img src={src} alt={key} style={{ margin: "0 44px" }} />
            ))}
          </div>

          <div className="srcGroup-m">
            <img src={srcGroupM} alt={"srcGroupM"} />
          </div>
        </div>
      </div>
    </div>

    <div className="bottom-percentage">
      <div className="font-36 semi-bold t-color mt-104">
        {title.appPercentage}
      </div>
      <div className="bottom block-middle mt-56">
        <div className="appPercentage">
          <img src={src2Left} alt={title.appPercentage} />
          <img src={src2Right} alt={title.appPercentage} />
        </div>
        <div className="appPercentage-m">
          <img src={src2Group} alt="src2Group"></img>
        </div>
      </div>
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="byTheNumber">
      <SEO {...china.seo} />
      <SolutionTopBanner
        {...china.topBanner}
        bgColor="linear-gradient(180deg,#F5F6FF 0%,#E7EAFF 100%)"
        bgPic={"/byTheNumbers/china/topBanner-bg.svg"}
        bannerPic={{
          src: `/byTheNumbers/china/topBanner-bg-m.png`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        height={590}
        pic={{
          src: "/byTheNumbers/china/topBanner-pic.png",
          style: {
            right: 0,
            bottom: "-47px",
            position: "absolute",
            width: "642px",
          },
        }}
      />

      <div className="text-center">
        <MarketOverview src="/byTheNumbers/china/market-overview.png" />
        <Sub src="/byTheNumbers/china/subscriber.jpg" />
        <ConnectionType
          leftSrc="/byTheNumbers/china/connectionType-1.png"
          rightSrc="/byTheNumbers/china/connectionType-2.png"
          bgColor="linear-gradient(180deg,#F5F6FF 0%,#E7EAFF 100%)"
          bgPic="/byTheNumbers/china/connectionType-bg.svg"
        />
        <div className="average-percentage">
          <Average
            src1="/byTheNumbers/china/daily.jpg"
            src2="/byTheNumbers/china/share.png"
            bgColor="linear-gradient(180deg,#FFFFFF 0%,#E7EAFF 100%)"
          />
          <Percentage
            src1="/byTheNumbers/china/gamePercentage.jpg"
            src2Left="/byTheNumbers/china/mobile-left.jpg"
            src2Right="/byTheNumbers/china/mobile-right.jpg"
            src2Group="/byTheNumbers/china/srcGroup-m.svg"
            srcGroup={[
              "On any device",
              "Smart phone",
              "PC",
              "Console",
              "Tablet",
            ].map(key => ({
              key,
              src: `/byTheNumbers/china/${key}.jpg`,
            }))}
            srcGroupM={"/byTheNumbers/china/srcGroup-m.png"}
          />
        </div>
      </div>
      <Footer />
    </div>
  )
}
